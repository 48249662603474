<template>
  <div class="manage-reservation">
    <PickCalendar title="예약 현황" :projectSeq="$route.params.projectSeq" :manager="true" :loaded="true"/>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import NoData from "@/components/NoData";
import PickCalendar from "@/components/PickCalendar.vue";

function Component(initialize) {
  this.name = "pageManagePickCalendarStatus";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PickCalendar, NoData},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component,};
  }
});
</script>

<style lang="scss" scoped>
.manage-reservation {
}
</style>